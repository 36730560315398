import {
  AlignItems,
  Box,
  ButtonGutter,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Glyph,
  IconButton,
  JustifyContent,
  Link,
  MoneyText,
  P,
  Space,
  TypePreset,
  Visibility,
  Text,
  Tag,
  TagVariant,
  TagColor,
  Banner,
  BannerVariant,
  MoneyTextVariant,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useRouter } from "next/router";
import { forwardRef, useCallback } from "react";

import { TrackingEvent } from "src/common/trackingEvents";
import { ToTranslate } from "src/components/i18n";
import {
  ContentName,
  DismissibleContent,
} from "src/components/layout/DismissibleContent";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { useOrganisation } from "src/queries/organisation";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import {
  useIsEligibleForPipe,
  usePipeStatus,
} from "src/common/hooks/useIsEligibleForPipe";
import { useUserPermissions } from "src/common/hooks/user-permissions/useUserPermissions";
import { Route, getRouteURL, routerPush } from "src/common/routing";

const defaultBannerName = "Pipe promotion";
const alternativeBannerName = "Embedded Pipe promotion";

export const PipeBanner = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const organisation = useOrganisation();
  const { sendEvent } = useSegment();
  const router = useRouter();
  const userPermissions = useUserPermissions();
  const isAdmin = !!userPermissions.isAdmin;
  const { isVariationOn: isInPipeEmbedExperiment } = useOptimizelyVariation({
    flag: OptimizelyFlag.ASAP_PAYMENTS_PIPE_EMBED,
  });
  const bannerName = isInPipeEmbedExperiment
    ? alternativeBannerName
    : defaultBannerName;
  const triggerBannerViewedEvent = useCallback(() => {
    sendEvent(TrackingEvent.BANNER_VIEWED, {
      page: router.pathname,
      name: bannerName,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  const pipeElligibility = useIsEligibleForPipe();
  const { pipeStatus } = usePipeStatus({
    shouldMakeRequest: isInPipeEmbedExperiment && isAdmin,
  });
  const shouldShowBanner = isInPipeEmbedExperiment
    ? !!pipeStatus?.pre_approved &&
      !pipeStatus?.active_advance && // do not show the banner if already active
      !!((pipeStatus.available_amount_in_cents ?? 0) > 0) //  or if the user spent it all
    : pipeElligibility;

  if (!shouldShowBanner || !organisation) {
    return null;
  }

  const {
    name: businessName,
    pipe_amount: amountFromPipeOrganisation,
    pipe_redemption_token: redemptionToken,
  } = organisation;

  const amount = isInPipeEmbedExperiment
    ? pipeStatus?.maximum_amount_in_cents
    : amountFromPipeOrganisation;
  const triggerBannerDismissedEvent = () => {
    sendEvent(TrackingEvent.BANNER_DISMISSED, {
      page: router.pathname,
      name: bannerName,
    });
  };
  const redeemHref = redemptionToken
    ? `https://app.pipe.com/partner/par_11Ujty5HkEi29hoR/redeem?token=${redemptionToken}&utm_source=banner`
    : `https://app.pipe.com/login?partner=banner`;
  const triggerBannerPrimaryCtaClicked = () => {
    sendEvent(TrackingEvent.BANNER_PRIMARY_CTA_CLICKED, {
      page: router.pathname,
      name: bannerName,
      destination: isInPipeEmbedExperiment
        ? getRouteURL({ route: Route.Capital })
        : redeemHref,
    });
  };
  const handleBannerDismiss = (dismiss: () => void) => {
    triggerBannerDismissedEvent();
    dismiss();
  };

  const defaultRenderContent = (dismiss: () => void) => {
    const closeButton = (
      <IconButton
        icon={Glyph.Close}
        label={<Trans id="Close">Close</Trans>}
        variant={ButtonVariant.TextOnLight}
        onClick={() => handleBannerDismiss(dismiss)}
        size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
      />
    );

    return (
      <Box
        gutterH={1}
        gutterV={1}
        borderRadius={1}
        layout="flex"
        alignItems={[AlignItems.Start, null, AlignItems.Center]}
        justifyContent={JustifyContent.SpaceBetween}
        bg={Color.Ocean_100}
        width="100%"
      >
        <Box
          layout="flex"
          flexDirection={["column", null, "row"]}
          justifyContent={JustifyContent.SpaceBetween}
          flexGrow={1}
          width="100%"
          spaceAfter={[0, null, 1]}
        >
          <Box
            layout="flex"
            flexDirection={["column", null, "row"]}
            spaceBelow={[1, null, 0]}
            alignItems={[AlignItems.Start, null, AlignItems.Center]}
          >
            <Box
              layout="flex"
              justifyContent={JustifyContent.SpaceBetween}
              flexGrow={1}
              width={["100%", null, "initial"]}
            >
              <Tag variant={TagVariant.Solid} color={TagColor.Primary}>
                <Text textTransform="uppercase">
                  <Trans id="new">New</Trans>
                </Text>
              </Tag>

              <Visibility visible={["block", null, "none"]}>
                {closeButton}
              </Visibility>
            </Box>
            <Space layout="inline" h={1} />
            <P
              color={ColorPreset.TextOnLight_01}
              preset={TypePreset.Body_02}
              spaceAbove={[1, null, 0]}
            >
              <ToTranslate>
                <>Unlock up to&nbsp;</>
                <MoneyText
                  format="short"
                  amount={(amount ?? 0) / 100}
                  currency="GBP"
                  locale="en"
                />
                <>&nbsp;in capital for {businessName}</>
              </ToTranslate>
            </P>
          </Box>
          <Space layout="inline" h={1} />
          <Link
            href={redeemHref}
            variant={ButtonVariant.SecondaryOnLight}
            size={ButtonSize.Sm}
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            target="_blank"
            onClick={triggerBannerPrimaryCtaClicked}
          >
            <ToTranslate>View offer</ToTranslate>
          </Link>
        </Box>
        <Visibility visible={["none", null, "block"]}>{closeButton}</Visibility>
      </Box>
    );
  };
  const alternativeRenderContent = (dismiss: () => void) => (
    <Banner
      elevation={0}
      variant={BannerVariant.Light}
      backgroundColor={Color.Ocean_100}
      title={
        <ToTranslate>
          <>Get up to&nbsp;</>
          <MoneyText
            format="short"
            amount={(amount ?? 0) / 100}
            currency={organisation.domestic_currency ?? ""}
            locale="en"
            variant={MoneyTextVariant.Flat}
          />
          <>&nbsp;capital powered by Pipe</>
        </ToTranslate>
      }
      leftAccessory={{
        type: BannerLeftAccessoryType.Tag,
        text: "NEW",
      }}
      primaryAction={{
        size: ButtonSize.Sm,
        control: "button",
        onClick: () => {
          ButtonSize.Sm, triggerBannerPrimaryCtaClicked();
          routerPush({
            route: Route.Capital,
          });
        },
        children: "Check my eligibility",
      }}
      closeAction={{
        onClose: () => handleBannerDismiss(dismiss),
        label: "Close",
      }}
    >
      <ToTranslate>
        Upgrade your equipment, expand marketing campaigns or strengthen your
        cash flow.
      </ToTranslate>
    </Banner>
  );

  return (
    <DismissibleContent
      ref={ref}
      name={
        isInPipeEmbedExperiment
          ? ContentName.EmbeddedPipeBannerDismissed
          : ContentName.PipeBannerDismissed
      }
      onContentMount={triggerBannerViewedEvent}
      renderContent={
        isInPipeEmbedExperiment
          ? alternativeRenderContent
          : defaultRenderContent
      }
    />
  );
});
